@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.cancelled-boot-camps {
  background: light.$background-secondary-default;
  height: 100%;

  &__title {
    color: light.$on-surface-primary-default;
  }

  &__container {
    margin: 0 auto;
    width: 100%;
    max-width: 1128px; // NOTE: This width should match with the list width
    padding-left: spacing.$s;
    padding-right: spacing.$s;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    margin-bottom: spacing.$m;
  }

  &__pagination {
    margin: 0 spacing.$m;
  }

  &__top-section {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    width: 100%;
    margin: spacing.$s 0 spacing.$m 0;
  }

  &__pagination {
    margin: 0 spacing.$m;
  }

  &__spinner {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.7);
  }

  &__link {
    display: flex;
    margin-bottom: spacing.$xs;
  }
}
